import React from "react"
import { BgImage } from "gbimage-bridge"

import Grid from "./Grid"

const Teaser = ({ image, children }) => (
	<Grid>
		<div className="row-span-1 row-start-1 col-start-1 col-span-11 lg:col-start-1 lg:col-span-7 z-0">
			<div className="flex rounded-medium overflow-hidden w-full h-260 md:h-360 lg:h-full">
				<BgImage
					image={image}
					Tag="div"
					className="overflow-hidden w-full h-auto"
					placeholder="blurred"
					objectFit="cover"
					objectPosition="center center"
				/>
			</div>
		</div>

		<div className="row-span-1 -mt-80 md:-mt-120 lg:mt-0 row-start-2 lg:row-start-1 col-start-2 col-span-11 lg:col-start-6 lg:col-span-7 z-10 py-0 lg:py-50 xl:py-60">
			{children}
		</div>
	</Grid>
)

export default Teaser
